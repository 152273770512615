import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment } from "react"

export default function ShareDialog(props) {
    const { isPresented, onClose, url, title } = props
    const [copySuccess, setCopySuccess] = React.useState(false);

    const copyToClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 1000);
        } catch (err) {
            setCopySuccess(false);
        }
    };

    return (


        <Transition
            show={isPresented}
            as={Fragment}
        >
            
            <Dialog
                onClose={() => { onClose && onClose() }}
                className=""
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 z-[200]" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500 transition transform"
                    enterFrom="opacity-0 -translate-y-80"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in-out duration-500 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="-translate-y-80"
                >

                    <Dialog.Panel className="bg-white rounded-lg drop-shadow-lg absolute top-20 right-4 p-5 max-w-xs w-full z-[200] text-sm">

                        <div className="flex items-start gap-3 w-full">
                            <CheckCircleIcon className="flex-none text-green-500 w-6 h-6" />

                            <div className="">
                                <p className="font-medium">{title ? title : "Successfully saved!"}</p>
                                <div className="mt-2 mb-4">
                                    <a href={url} target="_blank" className="text-primary break-all">{url}</a>
                                </div>
                                <div className="flex gap-6">
                                    <button onClick={() => { copyToClipBoard(url) }} className="font-medium text-primary">
                                        {
                                            copySuccess ?
                                                <span>Copied!</span>
                                                :
                                                <span>Copy URL</span>
                                        }
                                    </button>
                                    <button onClick={() => { onClose && onClose() }} className="font-medium ">
                                        Dismiss
                                    </button>
                                </div>
                            </div>

                            <button onClick={() => { onClose && onClose() }} className="flex-none ml-auto text-gray-500">
                                <XMarkIcon className="w-5 h-5" />
                            </button>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>

    )
}