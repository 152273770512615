import { _base_url } from '../constants'

export const reverseGeocoding = ({ lng, lat }) => {
    let text = `${lng},${lat}`
    let url = `${_base_url}/api/v2/mapbox/reverse/${text}?language=ja`
    return fetch(url)
        .then(response => response.json())
        .then(resp => {
            return resp
        })
        .catch((e) => {
            console.log("Geocoder failed due to: " + e)
            return e
        });
}

export const isochrone = ({ lng, lat, distance, meters, color }) => {
    let text = `${lng},${lat}`

    let url = `${_base_url}/api/v2/mapbox/isochrone/${text}?contours_minutes=5,10,15,30&contours_colors=08519c%2C3182bd%2C6baed6%2Cbdd7e7`
    if (distance) {
        url = `${_base_url}/api/v2/mapbox/isochrone/${text}?contours_minutes=${distance.max || distance.min}&contours_colors=${color ? color : "08519c"}`
    }

    if (meters)
        url = `${_base_url}/api/v2/mapbox/isochrone/${text}?contours_meters=${meters}&contours_colors=${color ? color : "08519c"}`

    return fetch(url)
        .then(response => response.json())
        .then(resp => {
            return resp
        })
        .catch((e) => {
            console.log("Geocoder failed due to: " + e)
            return e
        });
}