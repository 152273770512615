import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { Fragment } from "react"

export default function SuumoDetectedDialog(props) {
    const { isPresented, url, error, onClose, onGetLocations, isLoading } = props

    return (


        <Transition
            show={isPresented}
            as={Fragment}
        >

            <Dialog
                onClose={() => { onClose && onClose() }}
                className=""
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 z-[200]" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500 transition transform"
                    enterFrom="opacity-0 -translate-y-80"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in-out duration-500 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="-translate-y-80"
                >

                    <Dialog.Panel className="absolute top-0 left-0 flex w-full h-full items-center justify-center p-5">

                        <div className="bg-white rounded-lg p-5 z-[200] drop-shadow-lg flex flex-col max-w-xs">

                            <div className="flex gap-3 items-center">
                                <p>🥷</p>
                                <h1 className="font-medium text-center">
                                    <span className="text-[#6BA439] font-semibold">SUUMO</span> link detected!
                                </h1>
                                <button className="flex-none  ml-auto" onClick={() => {
                                    onClose && onClose()
                                }}>
                                    <XMarkIcon className="w-5 h-5" />
                                </button>
                            </div>

                            <div className="py-5">
                                <p className="break-all">{url}</p>

                                {
                                    isLoading ?
                                        <div className="mt-5 flex items-center">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span className="text-sm font-medium">Applying Ninja Spying Technique...</span>
                                        </div>
                                        : null
                                }

                                {error.length > 0 && <p className="text-red-500 mt-5 text-sm">{error}</p>}
                            </div>

                            {
                                isLoading == false ?
                                    <div className="mt-auto flex items-center gap-6">

                                        <button onClick={(e) => {
                                            onGetLocations && onGetLocations(url)
                                        }} className="text-primary font-medium">
                                            <span>Get locations</span>
                                        </button>
                                        <button className="font-medium text-gray-500" onClick={() => { onClose && onClose() }}>
                                            Dismiss
                                        </button>

                                    </div>
                                    : null
                            }

                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>

    )
}