import { _authorizedRequest, _authorizedDownloadRequest, _request } from './http'

export async function googleSignIn(auth) {
    return _request("POST", `/v2/auth/doki/google`, auth)
}

export async function trainStations(query, stationGroupCode, accessToken) {
    return _authorizedRequest("GET", `/v2/trains?query=${query}${stationGroupCode ? `&station_group_code=${stationGroupCode}` : ""}`, null, accessToken)
}

export async function trainRoutes(stationGroupCode, accessToken) {
    return _authorizedRequest("GET", `/v2/trains/${stationGroupCode}`, null, accessToken)
}

export async function fetchPlace(text, accessToken) {
    return _authorizedRequest("GET", `/v2/place/?query=${text.trim()}`, null, accessToken)
}


export async function searchPlace(text, center, accessToken) {
    return _authorizedRequest("GET", `/v2/en/place/search?query=${text.trim()}${center ? `&center=${center}` : ""}`, null, accessToken)
}

export async function fetchPlaceDetail(placeID, accessToken) {
    return _authorizedRequest("GET", `/v2/place/${placeID}`, null, accessToken)
}

export async function getRequestByCode(code, accessToken) {
    return _authorizedRequest("GET", `/v2/reverse/sharing/${code}`, null, accessToken)
}

export async function getRequest(accessToken) {
    return _authorizedRequest("GET", `/v2/reverse/sharing`, null, accessToken)
}

export async function saveRequestForSharing(data, accessToken) {
    return _authorizedRequest("POST", `/v2/reverse/sharing`, data, accessToken)
}

export async function fetchSuumoLink(link, accessToken) {
    return _authorizedRequest("POST", `/v2/reverse/sharing/suumo`, {link}, accessToken)
}

export async function deleteRequestByCode(code, accessToken) {
    return _authorizedRequest("Delete", `/v2/reverse/sharing/${code}`, null, accessToken)
}


