import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../api'

export const AppContext = React.createContext({});
export const AppContextProvider = ({ children }) => {

    const GOOGLE_OAUTH2_CLIENT_ID = '379613756082-57r2c458vvjajvgmr8ieqmbhrrs7059j.apps.googleusercontent.com'
    let auth;

    const [isReady, setIsReady] = React.useState(false);
    const [account, setAccount] = React.useState(null);
    const [language, setLanguage] = React.useState("ja");

    const accountSessionKey = "_vad";
    const getUserAccount = () => {
        const session = localStorage.getItem(accountSessionKey);
        if (session === null) {
            return null;
        }
        var a = JSON.parse(decodeURIComponent(escape(atob(session))))
        if (a === null || a.access_token === undefined) {
            return null;
        }
        return a;
    }

    const init = React.useCallback(async () => {
        var a = getUserAccount()
        if (a !== null) {
            setAccount(a)
            setIsReady(true)
        } else {
            setAccount(null)
            setIsReady(true)
        }
    }, []);

    React.useEffect(() => {
        init()
    }, [])


    const updateAccount = (account) => {
        var encodedAccount = btoa(unescape(encodeURIComponent(JSON.stringify(account))));
        localStorage.setItem(accountSessionKey, encodedAccount)
        var a = getUserAccount()
        if (a !== null) {
            setAccount(a)
        }
    }

    //methods
    const logout = () => {
        localStorage.removeItem(accountSessionKey)
        setAccount(null)
    }

    const signInWithGoogle = async (e) => {

        try {
            if (!auth) {
                await window.gapi.auth2.init({
                    client_id: GOOGLE_OAUTH2_CLIENT_ID,
                    scope: "profile email",
                    redirect_uri: window.location.origin,
                })
                auth = window.gapi.auth2.getAuthInstance()
            }
            const gLoginResponse = await auth.signIn()
            var id_token = gLoginResponse.getAuthResponse().id_token;
            let data = {
                "token": id_token,
                "source": window.location.href,
            }
            api.googleSignIn(data)
                .then(response => {
                    updateAccount(response)
                })
                .catch(errorResponse => {

                })
        } catch (error) {
                console.log(error)
        }
    }

    const contextValue = {
        //put context value here
        isReady,
        account,
        language,
        signInWithGoogle,
        logout,
    };

    return (React.createElement(AppContext.Provider, { value: contextValue }, children));
};

export const useAppContext = () => React.useContext(AppContext);
AppContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};