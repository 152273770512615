import { Transition, Dialog } from "@headlessui/react"
import { ChevronDoubleLeftIcon, ChevronLeftIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { Fragment } from "react"
import GettingStarted from "./GettingStarted"
export default function PlacesPanel(props) {
    const {
        isPresented,
        onClose,
        //for stations
        selectedTrainStations,
        onRemoveTrainStation,
        onUpdateTrainStationLine,
        onUpdateTrainStationDistance,

        //for places
        selectedPlaces,
        onRemovePlace,
        onUpdatePlaceDistance,
        onSetNote,
        note,
        onClearAll
    } = props


    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    var stringToColour = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    const clearAll = () => {
        onClearAll && onClearAll()
    }


    return (
        <Transition
            show={isPresented}
        >
            <>
                {/* <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                <div className="fixed inset-0 bg-black/30" />

                </Transition.Child> */}

                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0 -translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 -translate-x-full"
                >
                    <div className="absolute top-0 w-60 h-full pt-16 z-50 flex flex-col bg-white drop-shadow-lg">

                        <button onClick={() => { onClose() }} className="border-l absolute top-16 -right-5 w-5 h-full  bg-white flex items-center justify-center">
                            <ChevronDoubleLeftIcon className="w-3 h-3" />
                        </button>
                        {
                            selectedPlaces.length === 0 && selectedTrainStations.length === 0 ?
                                <GettingStarted/>
                                : null
                        }

                        <div className="bg-white h-full overflow-y-auto">

                            <div className="flex flex-col divide-y">

                                {
                                    selectedPlaces.length > 0 || selectedTrainStations.length > 0 ?
                                        <div className="flex items-center p-3">
                                            <p className="font-medium">Locations ({selectedPlaces.length + selectedTrainStations.length})</p>
                                            <button onClick={() => { clearAll() }} className="ml-auto text-sm font-medium border-b border-dashed text-gray-500">Clear</button>
                                        </div>
                                        : null
                                }


                                {
                                    selectedPlaces.map((place, i) => (
                                        <div key={`place-${i}`} className="p-3 flex flex-col">
                                            <div className="flex items-start gap-3 ">
                                                <div className="flex-none w-2 h-16 bg-gray-200" style={{ backgroundColor: "#" + stringToColour(place.place_id) }}>
                                                </div>
                                                <div className="">
                                                    <p className="font-medium mb-1">{place.name}</p>
                                                    <p className="text-xs text-gray-500">{place.address}</p>
                                                </div>
                                                <button className="ml-auto" onClick={() => {
                                                    onRemovePlace(place, i)
                                                }}>
                                                    <TrashIcon className="w-4 h-4 text-red-400" />
                                                </button>
                                            </div>
                                            <div className="mt-2">

                                                <label className="px-2 rounded border border-gray-300 w-full text-sm flex items-center">
                                                    <p className="flex-none text-xs">Distance (meters)</p>
                                                    <input inputMode="numeric" className="text-base text-right p-0 py-2 border-none focus:ring-0 w-full font-medium text-primary" type="number" defaultValue={place.distance.meters} min={20} max={5000} step={1}
                                                        onChange={(e) => { onUpdatePlaceDistance(place, e.target.value, i) }} />
                                                </label>
                                            </div>
                                        </div>


                                    ))
                                }

                                {
                                    selectedTrainStations.map((station, i) => (

                                        <div key={`station-${i}`} className="p-3 flex flex-col">

                                            <div className="flex items-start gap-3 h-full">

                                                {
                                                    station.line_color_hex &&
                                                    <div className={`flex-none w-2 h-16 flex items-center justify-center bg-gray-100`} style={{ backgroundColor: `#${station.line_color_hex}` }}>
                                                    </div>
                                                }

                                                <div className="flex flex-col w-full">

                                                    <div className="flex items-center mb-2">
                                                        <p className="font-medium">
                                                            {station.station_name_en.charAt(0).toUpperCase() + station.station_name_en.slice(1)}&nbsp;({station.station_name_jp})
                                                        </p>

                                                        <button className="ml-auto" onClick={() => {
                                                            onRemoveTrainStation(station, i)
                                                        }}>
                                                            <TrashIcon className="w-4 h-4 text-red-400" />
                                                        </button>


                                                    </div>

                                                    <div className="font-medium w-full">
                                                        {
                                                            station.routes ?
                                                                <select
                                                                    defaultValue={station.line_code}
                                                                    className="appearance-none rounded border border-gray-300 focus:outline-none focus:ring-0 px-2 py-1 w-full"
                                                                    onChange={(e) => {
                                                                        const route = station.routes.find(({ line_code }) => line_code === e.target.value)
                                                                        // console.log(e.target.value, station.routes, route)
                                                                        if (Number.isInteger(station.distance.max)) {
                                                                            onUpdateTrainStationLine(station, route, station.distance, i)
                                                                        }
                                                                    }}>
                                                                    {station.routes.map((r, index) => (
                                                                        <option key={r.line_code} value={r.line_code}>{r.line_name}</option>
                                                                    ))}
                                                                </select>
                                                                : null
                                                        }

                                                    </div>

                                                </div>

                                            </div>

                                            <div className=" mt-2">

                                                <label className="px-2 rounded border border-gray-300 w-full text-sm flex items-center">
                                                    <p className="flex-none text-xs">Walking (minutes)</p>

                                                    <input inputMode="numeric" className="text-base text-right p-0 py-2 border-none focus:ring-0 w-full font-medium text-primary" type="number" name={`marker-distance-${i}`} defaultValue={station.distance.max} min={1} max={60} onChange={(e) => {
                                                        onUpdateTrainStationDistance(station, e.target.value, i)
                                                    }} />

                                                </label>



                                            </div>

                                        </div>
                                    ))}
                            </div>
                        </div>

                        {
                            selectedPlaces.length > 0 || selectedTrainStations.length > 0 ?
                                <div className="p-3  border-t w-full bg-white">
                                    <p className="text-sm font-medium">Note</p>
                                    <textarea placeholder="Your note here" rows={3} className="text-base text-left p-0 border-none focus:ring-0 w-full font-medium text-primary placeholder-gray-200"
                                        onChange={(e) => {
                                            onSetNote(e.target.value)
                                        }}
                                        value={note}
                                    />
                                </div>
                                : null
                        }


                    </div>
                </Transition.Child>
            </>
        </Transition>
    )
}