import * as Constants from '../constants'
export async function _get(url) {
    var options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(url, options);

    let status = response.status;

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}

export async function _authorizedRequest(method, resourceUrl, postBody, accessToken) {
    return makerequest(method, resourceUrl, postBody, true, accessToken)
}


export async function _request(method, resourceUrl, postBody, accessToken) {
    return makerequest(method, resourceUrl, postBody, false, accessToken)
}

async function makerequest(method, resourceUrl, postBody, authorizedRequest, accessToken) {
    
    let headers = {
        'Content-Type': 'application/json'
    }

    if (authorizedRequest === true) {
        if (accessToken) {
            headers["Authorization"] = `Bearer ${accessToken}`;
        }
    }

    var options = {
        method: method,
        headers: headers,
        body: postBody !== null && postBody !== undefined ? JSON.stringify(postBody) : null
    }

    const response = await fetch(Constants.API_BASE_URL + resourceUrl, options);
    let status = response.status;
    // if (status === 401) {
    //     //unautorized
    //     //clear localStorage
    //     localStorage.removeItem("_vad")
    //     window.location.href = "/"
    //     return null;
    // }

    // if (status === 505) {
    //     //HTTP Version Not Supported
    //     //clear localStorage
    //     localStorage.removeItem("_vad")
    //     window.location.href = "/login"
    //     return null;
    // }

    // if (status === 402) {
    //     window.open("/", "_self")
    //     return null;
    // }


    if (status !== 200) {
        let error = new Error();
        let isParseError = false
        try {
            let e = await response.json()
            error = { ...error, statusCode: status, data: e };
        } catch (e) {
            isParseError = true
        }

        if (isParseError) {
            error = { ...error, statusCode: status, data: { message: response.statusText } };
            throw error;
        }

        throw error;

    }

    let data = await response.json()
    return data;
}
