
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Main from './views/Main';

const history = createBrowserHistory();

function App() {

  return (
      <Routes>
        <Route path="/:shareLinkID" element={<Main history={history} />} />
        <Route path="/" element={<Main history={history}/>} />
      </Routes>
  );
}

export default App;
