import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import * as api from '../../api'


import { useAppContext } from "../../contexts/AppContext";
import { Bars3Icon, ChevronLeftIcon, CloudArrowUpIcon, MapPinIcon, ShareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import PlacesPanel from "./PlacesPanel";
import AccountPanel from "./AccountPanel";
import ShareDialog from "./ShareDialog";
import SuumoDetectedDialog from "./SuumoDetectedDialog";
import PlaceSearchInput from "../Components/PlaceSearchInput";
import Map from "./Map";
import * as mapbox from '../../mapbox/api'
import { debounce } from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider, HelmetData } from 'react-helmet-async';
import AboutDialog from './AboutDialog'

export default function Main(props) {

    let { shareLinkID } = useParams();
    const navigate = useNavigate();

    const appContext = useAppContext()

    const [suumoLink, setSuumoLink] = useState("")
    const [suumoError, setSuumoError] = useState("")
    const [isFetchingSuumo, setIsFetchingSuumo] = useState(false)
    const [suumoDialog, setSuumoDialog] = useState(false)
    const [isLeftPanelIsPresented, setIsLeftPanelIsPresented] = useState(false)
    const [isAccountPanelPresented, setIsAccountPanelPresented] = useState(false)

    const [shareDialogInfo, setShareDialogInfo] = useState({ url: "", title: "" })
    const [isShareDialogPresented, setIsShareDialogPresented] = useState(false)

    const [selectedTrainStations, setSelectedTrainStations] = useState([])
    const [selectedPlaces, setSelectedPlaces] = useState([])
    const [mapViewState, setMapViewState] = useState(null)

    const query = new URLSearchParams(window.location.search)
    const [search, setSearch] = useState(query.get("query") ? { places: [], stations: [], ...JSON.parse(query.get("query")) } : ({
        places: [],
        stations: [],
    }))
    const [note, setNote] = useState("")
    const [savedMapByID, setSavedMapByID] = React.useState(null) // this is a data when coming from ID e.g. https://doko.ninja/qStxYN
    const [title, setTitle] = React.useState("Find that location")
    const [metaDescription, setMetaDescription] = React.useState("Introducing the doko.ninja! This handy tool is your go-to solution for determining the location of any mysterious spot. Simply input the distance or walking time to at least three known points, and the doko.ninja will do the rest! Whether you're trying to find a hidden treasure, or just trying to meet up with friends at a specific location, the doko.ninja has got you covered. With its advanced multilateration technology, you'll be able to pinpoint the location with ease. So why wait? Try out doko.ninja today and find the location of that mysterious spot!")

    var stringToColour = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }


    const saveAndShare = async () => {
        //save to database here and then show share dialog

        if (search.places.length === 0 && search.stations.length === 0) {
            return
        }

        search.note = note

        api.saveRequestForSharing(search, appContext.account ? appContext.account.access_token : null)
            .then(response => {
                const url = window.location.origin + "/" + response.data
                setShareDialogInfo(
                    {
                        url: url,
                        title: "Saved successfully!"
                    }
                )
                setIsShareDialogPresented(true)
                props.history.push({
                    pathname: `/${response.data}`,
                })
            })
            .catch(errorResponse => {
                console.error("error response", errorResponse)
            })
    }

    const onSelectedPlaceOrStation = (selected) => {
        if (selected.type === "train_station") {
            if (selected === null) {
                return;
            }
            //show left panel
            setIsLeftPanelIsPresented(true)

            api.trainRoutes(selected.result.station_group_code)
                .then((res) => {
                    const routes = res.data
                    if (routes.length > 0) {

                        //default select to first line
                        const firstLine = routes[0]
                        const defaultDistance = { max: 5 }
                        mapbox.isochrone({
                            lat: firstLine.latlng.latitude,
                            lng: firstLine.latlng.longitude,
                            distance: defaultDistance,
                            color: firstLine.line_color_hex,
                        }).then((res) => {
                            setSelectedTrainStations((d) => [...d, {
                                ...selected.result,
                                routes: routes,
                                distance: { max: 5 },
                                line_color_hex: firstLine.line_color_hex,
                                line_code: firstLine.line_code,
                                line_name: firstLine.line_name,
                                latlng: {
                                    latitude: firstLine.latlng.latitude,
                                    longitude: firstLine.latlng.longitude,
                                },
                                walkingPath: res,
                            }])

                        })
                    }

                })
        } else if (selected.type === "place") {


            const place = selected.result;
            const defaultMeters = 100
            const name = place.name
            const address = place.formatted_address
            const location = place.geometry.location
            const place_id = place.place_id
            const color = stringToColour(place.place_id)
            //show left panel
            setIsLeftPanelIsPresented(true)

            mapbox.isochrone({
                lat: location.lat,
                lng: location.lng,
                meters: defaultMeters,
                color: stringToColour(place.place_id),
            }).then((res) => {

                setSelectedPlaces((d) => [...d, {
                    ...place,
                    name: name,
                    address: address,
                    type: "place",
                    color: color,
                    distance: { meters: defaultMeters },
                    latlng: {
                        latitude: location.lat,
                        longitude: location.lng,
                    },
                    walkingPath: res
                }])

            })

        }
    }

    const onSuumoLinkDetected = (link) => {
        setSuumoDialog(true)
        setSuumoLink(link)
    }

    const onRemoveTrainStation = (station, index) => {
        setSelectedTrainStations((stations) => {
            stations.splice(index, 1);
            return [...stations]
        })
    }

    const onUpdateTrainStationLine = (station, line, distance, index) => {
        // console.log(station, line, distance, index)
        mapbox.isochrone({
            lat: line.latlng.latitude,
            lng: line.latlng.longitude,
            distance: distance,
            color: line.line_color_hex,
        }).then((res) => {
            setSelectedTrainStations((stations) => {
                stations[index].line_color_hex = line.line_color_hex
                stations[index].line_code = line.line_code
                stations[index].line_name = line.line_name
                stations[index].latlng.latitude = line.latlng.latitude
                stations[index].latlng.longitude = line.latlng.longitude
                stations[index].walkingPath = res
                return [...stations]
            })
        })
    }

    const onUpdateTrainStationDistance = useMemo((station, distanceMinuteString, index) => {
        return debounce((station, distanceMinuteString, index) => {

            if (distanceMinuteString.length === 0) {
                setSelectedTrainStations((stations) => {
                    stations[index].distance.max = ""
                    return [...stations]
                })
                return
            }

            try {
                var minutes = Number.parseInt(distanceMinuteString, 10)
                if (minutes > 60) {
                    minutes = 60
                }

                setSelectedTrainStations((stations) => {
                    stations[index].distance.max = minutes
                    return [...stations]
                })

                mapbox.isochrone({
                    lat: station.latlng.latitude,
                    lng: station.latlng.longitude,
                    distance: { max: minutes },
                    color: station.line_color_hex,
                }).then((res) => {
                    setSelectedTrainStations((stations) => {
                        stations[index].distance.max = minutes
                        stations[index].walkingPath = res
                        return [...stations]
                    })
                })
            } catch (error) {

            }

        }, 750)
    }, [selectedTrainStations])


    const onRemovePlace = (place, index) => {
        setSelectedPlaces((places) => {
            places.splice(index, 1);
            return [...places]
        })
    }

    const onClearAll = () => {
        setSelectedPlaces([])
        setSelectedTrainStations([])
    }

    const onUpdatePlaceDistance = useMemo((place, distanceMetersString, index) => {
        return debounce((place, distanceMetersString, index) => {
            // console.log(place, distanceMetersString, index)

            if (distanceMetersString.length === 0) {
                setSelectedPlaces((places) => {
                    places[index].distance.meters = ""
                    return [...places]
                })
                return
            }


            try {
                let distance = Number.parseInt(distanceMetersString, 10)
                if (distance > 5000) { //max is 5km
                    distance = 5000
                }

                setSelectedPlaces((places) => {
                    places[index].distance.meters = distance
                    return [...places]
                })
                mapbox.isochrone({
                    lat: place.latlng.latitude,
                    lng: place.latlng.longitude,
                    meters: distance,
                    color: place.color,
                }).then((res) => {
                    if (res.features.length === 0) {
                        return
                    }

                    var first = res.features[0]
                    if (first.geometry.coordinates.length === 0) {
                        return
                    }
                    setSelectedPlaces((places) => {
                        places[index].distance.meters = distance
                        places[index].walkingPath = res
                        return [...places]
                    })

                })
            } catch (error) {
                console.log("error", error)
            }

        }, 750)
    }, [selectedPlaces])

    //watch these two params to construct search data so we can save in our database
    useEffect(() => {
        var list = { places: [], stations: [] }

        if (selectedTrainStations !== null) {
            let stationsSearch = selectedTrainStations.map((station) => {
                return {
                    station_name_jp: station.station_name_jp,
                    station_name_en: station.station_name_en,
                    line_name: station.line_name,
                    station_group_code: station.station_group_code,
                    distance: station.distance,
                    line_color_hex: station.line_color_hex,
                    line_code: station.line_code,
                    line_name: station.line_name,
                    latlng: station.latlng,
                }
            })
            stationsSearch.map((s) => {
                list.stations.push(s)
            })
        }

        if (selectedPlaces !== null) {

            let places = selectedPlaces.map((place) => {
                return {
                    place_id: place.place_id,
                    distance: place.distance,
                    name: place.name,
                    address: place.address,
                    type: "place",
                    color: place.color,
                    distance: place.distance,
                    latlng: place.latlng,
                }
            })

            places.map((s) => {
                list.places.push(s)
            })
        }

        var listPlaces = list.places.map((p) => {
            return `${p.distance.meters}m from ${p.name}`
        })

        var listStations = list.stations.map((p) => {
            return `${p.distance.max} mins from ${p.station_name_en} station`
        })

        if (listPlaces.length > 0 || listStations.length > 0) {
            setTitle(listPlaces.concat(listStations).join(", "))
        }
        setSearch(list)
    }, [selectedPlaces, selectedTrainStations])


    const toggleLeftPanel = () => {
        setIsLeftPanelIsPresented(isLeftPanelIsPresented => !isLeftPanelIsPresented)
    }

    React.useEffect(() => {

        if (savedMapByID === null) {
            return
        }

        var listPlaces = savedMapByID.places.map((p) => {
            return `${p.distance.meters}m from ${p.name}`
        })

        var listStations = savedMapByID.stations.map((p) => {
            return `${p.distance.max} mins from ${p.station_name_en} station`
        })

        setTitle(listPlaces.concat(listStations).join(", "))

    }, [savedMapByID])

    const getSavedPlaces = async (id) => {
        try {
            let result = await api.getRequestByCode(shareLinkID, appContext.account ? appContext.account.access_token : null)
            setSavedMapByID(result.data)
            setNote(result.data.note)
            const placesWithData = await Promise.all(result.data.places.map(async (place) => {
                const walkingPath = await mapbox.isochrone({
                    lat: place.latlng.latitude,
                    lng: place.latlng.longitude,
                    meters: place.distance.meters,
                    color: place.color,
                })
                place.walkingPath = walkingPath
                return place
            }))
            setSelectedPlaces(placesWithData)

            const stationsWithData = await Promise.all(result.data.stations.map(async (station) => {
                const routeResult = await api.trainRoutes(station.station_group_code)
                station.routes = routeResult.data

                const walkingPath = await mapbox.isochrone({
                    lat: station.latlng.latitude,
                    lng: station.latlng.longitude,
                    distance: station.distance,
                    color: station.line_color_hex,
                })

                station.walkingPath = walkingPath
                return station
            }))
            setSelectedTrainStations(stationsWithData)

        } catch (error) {
            setSavedMapByID(null)
            if (error.statusCode === 400) {
                navigate("/", { replace: true })

            }
        }
    }

    const fetchSuumoLocation = () => {
        //show loading
        setIsFetchingSuumo(true)
        try {
            api.fetchSuumoLink(suumoLink)
            .then(async (res) => {
                const { suumo_result, places, stations } = res.data

                if (suumo_result.error) {
                    setIsFetchingSuumo(false)
                    setSuumoError("Uh oh, looks like the locations in that link is in a format we can't understand.")
                    return
                }

                if (Object.keys(suumo_result.place_pattern).length === 0 && Object.keys(suumo_result.station_pattern).length === 0) {
                    // cannot fetch from those url, need to improve
                    setIsFetchingSuumo(false)
                    setSuumoError("Uh oh, looks like the locations in that link is in a format we can't understand.")
                    return
                }


                const placesWithData = await Promise.all(places.map(async (place) => {
                    const walkingPath = await mapbox.isochrone({
                        lat: place.latlng.latitude,
                        lng: place.latlng.longitude,
                        meters: place.distance.meters,
                        color: stringToColour(place.place_id),
                    })
                    place.color = stringToColour(place.place_id)
                    place.walkingPath = walkingPath
                    return place
                }))
                setSelectedPlaces(placesWithData)

                const stationsWithData = await Promise.all(stations.map(async (station) => {
                    const routeResult = await api.trainRoutes(station.station_group_code)
                    station.routes = routeResult.data

                    const walkingPath = await mapbox.isochrone({
                        lat: station.latlng.latitude,
                        lng: station.latlng.longitude,
                        distance: station.distance,
                        color: station.line_color_hex,
                    })

                    station.walkingPath = walkingPath
                    return station
                }))
                setSelectedTrainStations(stationsWithData)

                setIsFetchingSuumo(false)
                setSuumoDialog(false)
            })
        } catch (error) {
            
        }
        
    }

    useEffect(() => {

        if (appContext.isReady === false) {
            return
        }
        if (shareLinkID !== null && shareLinkID !== undefined) {
            getSavedPlaces(shareLinkID)
        }
    }, [shareLinkID, appContext.isReady])

    function getMiddle(prop, markers) {
        let values = markers.map(m => m[prop]);
        let min = Math.min(...values);
        let max = Math.max(...values);
        if (prop === 'lng' && (max - min > 180)) {
            values = values.map(val => val < max - 180 ? val + 360 : val);
            min = Math.min(...values);
            max = Math.max(...values);
        }
        let result = (min + max) / 2;
        if (prop === 'lng' && result > 180) {
            result -= 360
        }
        return result;
    }

    function findCenter(markers) {
        return {
            lat: getMiddle('lat', markers),
            lng: getMiddle('lng', markers)
        }
    }

    const mapboxStaticImage = (data) => {
        const { places, stations } = data

        const latlngs = places.concat(stations).map((p) => {
            return {
                lng: p.latlng.longitude,
                lat: p.latlng.latitude,
            }
        })

        var placesPins = places.map((p) => {
            return `pin-s+${p.color}(${p.latlng.longitude},${p.latlng.latitude})`
        })

        var stationPins = stations.map((p) => {
            return `pin-s+${p.line_color_hex}(${p.latlng.longitude},${p.latlng.latitude})`
        })

        const center = findCenter(latlngs)
        const pins = placesPins.concat(stationPins).join(",")
        const key = "pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdlMW83MngxMjJvcGFhN2d2cmNkNyJ9.K2ko2jB7iJ8o-_6C6WeNyw"
        const size = "400x200"
        const latlng = center.lng + "," + center.lat + ",12,0"
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${pins}/${latlng}/${size}@2x?access_token=${key}`
    }


    const [isAboutDialogPresented, setIsAboutDialogPresented] = React.useState(JSON.parse(localStorage.getItem("_is_about_presented")) ?? true)
    

    return (
        <>
            <Helmet>
                <title>{title} | doko.ninja</title>
                <link rel="canonical" href={`https://doko.ninja/${shareLinkID ?? ''}`} />
                <meta property="og:title" content={`${title} | doko.ninja`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://doko.ninja/${shareLinkID}"`} />
                <meta property="og:image" content={savedMapByID ? mapboxStaticImage(savedMapByID) : `https://doko.ninja/og.png`} />
                <meta property="og:description" content={`${savedMapByID ? title : metaDescription}`} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@dokoninja" />
                <meta name="twitter:creator" content="@dokoninja" />

            </Helmet>

            <div className="absolute inset-0 w-full flex flex-col overflow-hidden">



                <div id="map" className="w-full h-full bg-gray-400">
                    <Map
                        places={selectedPlaces}
                        trainStations={selectedTrainStations}
                        onMoveEnd={(viewState) => { setMapViewState(viewState) }}
                    />
                </div>

                <PlacesPanel
                    isPresented={isLeftPanelIsPresented}
                    onClose={() => { setIsLeftPanelIsPresented(false) }}
                    selectedTrainStations={selectedTrainStations}
                    onRemoveTrainStation={onRemoveTrainStation}
                    onUpdateTrainStationDistance={onUpdateTrainStationDistance}
                    onUpdateTrainStationLine={onUpdateTrainStationLine}

                    selectedPlaces={selectedPlaces}
                    onRemovePlace={onRemovePlace}
                    onUpdatePlaceDistance={onUpdatePlaceDistance}
                    onSetNote={setNote}
                    note={note}
                    onClearAll={onClearAll}

                />

                <AccountPanel isPresented={isAccountPanelPresented} onClose={() => { setIsAccountPanelPresented(false) }} setShareDialogInfo={setShareDialogInfo} setIsShareDialogPresented={setIsShareDialogPresented} />

                <div className="absolute top-0 left-0 w-full bg-white drop-shadow-sm h-16 z-[100]">

                    <div className="flex items-center justify-center bg-white gap-3 p-3">
                        <button onClick={toggleLeftPanel} className="focus:outline-none flex-none w-10 h-10 flex items-center justify-center relative">
                            <Bars3Icon className="w-8 h-8" />
                            {
                                selectedTrainStations.length > 0 || selectedPlaces.length ?

                                    <div className="rounded-full absolute -top-1 -right-1 bg-primary text-white z-50 w-5 h-5 flex items-center justify-center text-xs font-semibold">
                                        {selectedPlaces.length + selectedTrainStations.length}
                                    </div>
                                    : null
                            }

                        </button>


                        <PlaceSearchInput
                            mapViewState={mapViewState}
                            onSelectedResult={(result) => { onSelectedPlaceOrStation(result) }}
                            onSuumoLinkDetected={(link) => { onSuumoLinkDetected(link) }}
                            clearAfterSelected={true} />
                        <button onClick={() => { setIsAccountPanelPresented(!isAccountPanelPresented) }} className="flex-none rounded-full w-10 h-10 bg-white flex items-center justify-center border-2 border-primary ">
                            🥷
                        </button>

                        <div className="">
                            <button disabled={selectedPlaces.length === 0 && selectedTrainStations.length === 0} onClick={(e) => { saveAndShare(e) }} className="disabled:bg-opacity-30 flex items-center justify-center gap-2 bg-primary text-white rounded px-2 py-2  font-medium">
                                <CloudArrowUpIcon className="w-5 h-5" />
                                <span>Share</span>
                            </button>
                        </div>

                    </div>
                </div>

                <ShareDialog url={shareDialogInfo.url} title={shareDialogInfo.title} isPresented={isShareDialogPresented} onClose={() => { setIsShareDialogPresented(false) }} />
                {suumoDialog && <SuumoDetectedDialog isLoading={isFetchingSuumo} url={suumoLink} error={suumoError} fetchingSuumo onGetLocations={fetchSuumoLocation} isPresented={suumoDialog} onClose={() => { setSuumoLink(""); setSuumoDialog(false) }} />}

                <AboutDialog isPresented={isAboutDialogPresented} onClose={()=>{setIsAboutDialogPresented(false); localStorage.setItem("_is_about_presented",false)}}/>
            </div>
        </>
    )
}