import React from "react";
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export default function About() {


    return (
        <Disclosure defaultOpen={true}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="w-full focus:outline-none">
                                <div className="font-medium flex items-center w-full">
                                    <p className="">🥷 About <span className="text-primary font-medium">doko.ninja</span></p>
                                    <div className="ml-auto">
                                        <ChevronRightIcon className={` w-5 h-5 ease-in-out duration-200 transition ${open ? "rotate-90 transform" : ""}`} />

                                    </div>
                                </div>
                            </Disclosure.Button>
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel className="mt-2 text-gray-500">
                                    <div className="flex flex-col">
                                        <p className="mb-3">Have you ever wondered where is that mysterious place between 3 train stations from different walking distances?</p>
                                        <div className="mb-3">Introducing the <span className="text-primary font-medium">doko.ninja</span>! This handy tool is your go-to solution for determining the location of any mysterious spot. Simply input the distance or walking time to at least three known points, and the <span className="text-primary font-medium">doko.ninja</span> will do the rest!

                                            Whether you're trying to find a hidden treasure, or just trying to meet up with friends at a specific location, the <span className="text-primary font-medium">doko.ninja</span> has got you covered. With its advanced multilateration technology, you'll be able to pinpoint the location with ease.

                                            <p>So why wait? Try out <span className="text-primary font-medium">doko.ninja</span> today and find the location of that mysterious spot!</p>
                                            <p className="mt-3">"Doko" (どこ) is a Japanese word that means "Where".</p>
                                        </div>
                                        <p className="mb-1 text-gray-400">Contact: <a href="mailto:ninja@viila.co?subject=doko" className="text-primary">ninja@viila.co</a></p>
                                        <p className="text-xs text-gray-400">©Viila Technologies. All rights reserved.</p>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
    )
}