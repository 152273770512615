import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { ArrowRightOnRectangleIcon, ChevronRightIcon, ShareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Fragment } from "react"
import { useAppContext } from "../../contexts/AppContext";
import SavedMaps from "./SavedMaps";
import NoAccount from "./NoAccount";

export default function AccountPanel(props) {

    const { isPresented, onClose, setShareDialogInfo, setIsShareDialogPresented } = props
    const appContext = useAppContext()
    const { account, language } = appContext

    return (
        <Transition
            show={isPresented}
            as={Fragment}
        >
            <Dialog onClose={() => onClose()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/30" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease-in-out duration-300 transition transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="fixed top-0 right-0 w-72 h-full pt-16 z-50 drop-shadow-lg">
                        <div className="bg-white h-full overflow-y-auto">

                            {
                                account ?
                                    <SavedMaps setShareDialogInfo={setShareDialogInfo} setIsShareDialogPresented={setIsShareDialogPresented} />
                                    :
                                    <NoAccount />
                            }
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>

    )
}