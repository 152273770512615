import React, { Fragment, useRef, useState, useMemo, useEffect } from "react";
import { MAPBOX_TOKEN } from '../../constants'
import * as turf from '@turf/turf';
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';
import { getCursor, scaleControlStyleNoPadding, walkingPathStyle, walkingPathPopUpStyle, navStyleNoPadding, geolocateStyleNoPadding, intersectionStyle,centerOfIntersectionStyle } from '../../mapbox/style'
import TrainIcon from "../../Icons/TrainIcon";
import { MapPinIcon } from "@heroicons/react/24/solid";
export default function Map(props) {

    const { places, trainStations, onMoveEnd } = props
    const mapRef = useRef()

    const [mapPopup, setMapPopup] = useState({display: false})

    const [stationPopup, setStationPopup] = useState(null)
    const [placePopup, setPlacePopup] = useState(null)

    const walkingDistance = useMemo(() => {
        const a1 = trainStations.reduce((all, item) => {
            if (item.walkingPath && all.features) {
                all.features.push(...item.walkingPath.features.map((feature) => {
                    // console.log("stationPopup", stationPopup, "item", item)
                    feature.properties["selected"] = stationPopup && stationPopup.line_code === item.line_code ? true: false
                    return feature
                }))
            }
            return all
        }, { type: "FeatureCollection", features: [] })

        const a2 = places.reduce((all, item) => {
            if (item.walkingPath && all.features) {
                all.features.push(...item.walkingPath.features.map((feature) => {
                    // console.log("placePopup", placePopup, "item", item)
                    feature.properties["selected"] = placePopup && placePopup.place_id === item.place_id  ? true: false
                    return feature
                }))
            }
            return all
        }, { type: "FeatureCollection", features: [] })
        return { type: "FeatureCollection", features: a1.features.concat(a2.features) }
    }, [trainStations, places, stationPopup, placePopup])


    const intersection = useMemo(() => {
        if (walkingDistance.features.length < 2) {
            return null
        }
        return walkingDistance.features.reduce((x, y) => {
            if (turf.intersect(x, y) == null) {
                return turf.union(x, y)
            }
            return turf.intersect(x, y)
        })
    }, [walkingDistance])

    const centersOfIntersection = useMemo(()=>{
        if (intersection !== null) {
            var center = turf.centerOfMass(intersection);
            const p = turf.pointOnFeature(center)
            return center
        }
        return null
    },[intersection])


    useEffect(() => {
        if ((trainStations.length > 0 || places.length > 0)) {
            if (walkingDistance.features.length > 0) {
                const features = turf.featureCollection(walkingDistance.features)
                const bbox = turf.bbox(features)
                mapRef?.current?.fitBounds(bbox, { padding: 40, duration: 1000 })
            }
        }

    }, [trainStations, places, walkingDistance])


    const markerStyle = (hex) => {
        return {
            backgroundColor: `#${hex}`
        }
    }

     React.useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
        const name= "ninja-marker"
      map.loadImage("/ninja_marker.png", (error, image) => {
        if (error) throw error;
        if (!map.hasImage(name)) map.addImage(name, image, { 'sdf': false });
      });
    }
  }, [mapRef.current]);

    return (

        <ReactMapGL
            ref={mapRef}
            attributionControl={false}
            cursor={getCursor}
            mapStyle="mapbox://styles/apisitviila/cl1vo7gbg006p14miqclslj60"
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={{
                latitude: 35.6768718,
                longitude: 139.7817507,
                zoom: 11,
            }}
            style={{ width: "100%", height: "100%" }}
            doubleClickZoom={false}
            onDblClick={(e) => {
                e.originalEvent.stopPropagation();
                setMapPopup({ 
                    display: true,
                    latitude: e.lngLat.lat,
                    longitude: e.lngLat.lng,
                })
            }}
            onMoveEnd = {(e)=> {
                if (onMoveEnd) {
                    onMoveEnd(e.viewState)
                }
            }}
        >
            {trainStations.map((station, i) => (
                <Fragment key={`marker-${i}-${station.station_name_en}`}>
                    <Marker
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setStationPopup(station)
                        }
                        }
                        latitude={station.latlng.latitude}
                        longitude={station.latlng.longitude}
                    >

                        <div className="w-6 h-6 rounded-full flex items-center justify-center drop-shadow-lg" style={markerStyle(station.line_color_hex)}>
                            <TrainIcon className="w-4 h-4 text-white" />
                        </div>
                    </Marker>
                </Fragment>
            ))}

            {places.map((place, i) => (
                <Fragment key={`place-${i}`}>
                    <Marker
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setPlacePopup(place)
                        }}
                        latitude={place.latlng.latitude}
                        longitude={place.latlng.longitude}
                    >
                        <div className="w-6 h-6 rounded-full flex items-center justify-center drop-shadow-lg" style={markerStyle(place.color)}>
                            <MapPinIcon className="w-4 h-4 text-white" />
                        </div>
                    </Marker>
                </Fragment>
            ))}

            

            {walkingDistance && (<Source id={`source-walkpath`} type="geojson" data={{...walkingDistance, features: [...walkingDistance.features.filter(({properties}) => properties.selected === false)]}} >
                <Layer id={`layer-walkpath`}  {...walkingPathStyle} />
            </Source>)}
            {walkingDistance && (<Source id={`source-popup`} type="geojson" data={{...walkingDistance, features: [...walkingDistance.features.filter(({properties}) => properties.selected === true)]}} >
                <Layer id={`layer-popup`}  {...walkingPathPopUpStyle} />
            </Source>)}

          

            {intersection && (<Source id={`source-intersection`} type="geojson" data={intersection}>
                <Layer id={`layer-intersection`}  {...intersectionStyle} />
            </Source>)}

         
            {centersOfIntersection && (<Source id={`source-intersection-center`} type="geojson" data={centersOfIntersection}>
                <Layer id={`layer-intersection-center`}  {...centerOfIntersectionStyle} />
            </Source>)}
            {
                stationPopup !== null ?
                    <Popup
                        className="p-0"
                        anchor="bottom"
                        offset={12}
                        latitude={stationPopup.latlng.latitude}
                        longitude={stationPopup.latlng.longitude}
                        closeOnClick={true}
                        closeButton={false}
                        onClose={(e) => {
                            setStationPopup(null)
                        }}
                    >
                        <div className=" p-2">
                            <p className="text-xs font-medium">{stationPopup.station_name_en.charAt(0).toUpperCase() + stationPopup.station_name_en.slice(1)} ({stationPopup.station_name_jp}) · {stationPopup.line_name}</p>
                            <p className="text-gray-500">{stationPopup.distance.max} mins walking distance</p>
                        </div>
                    </Popup>
                    : null
            }

            {
                placePopup !== null ?
                    <Popup
                        className="p-0"
                        anchor="bottom"
                        offset={12}
                        latitude={placePopup.latlng.latitude}
                        longitude={placePopup.latlng.longitude}
                        closeOnClick={true}
                        closeButton={false}
                        onClose={(e) => {
                            setPlacePopup(null)
                        }}
                    >
                        <div className=" p-2">
                            <p className="text-xs font-medium">{placePopup.name}</p>
                            <p className="text-gray-500">{placePopup.distance.meters} meters walking distance</p>
                        </div>
                    </Popup>
                    : null
            }

            {
                mapPopup.display === true ?
                    <Popup
                        className="p-0"
                        anchor="bottom"
                        latitude={mapPopup.latitude}
                        longitude={mapPopup.longitude}
                        closeOnClick={true}
                        closeButton={false}
                        onClose={(e) => {
                            setMapPopup({display: false})
                        }}
                    >
                        <div className="text-xs">
                            <a href={`https://maps.google.com/maps?q=${mapPopup.latitude},${mapPopup.longitude}`} target="_blank" rel="noreferer">
                                <p className="p-2">{mapPopup.latitude.toPrecision(5)},{mapPopup.longitude.toPrecision(5)}</p>
                            </a>
                            <div className="border-t px-2 py-2">
                                <a href={`https://www.google.com/maps?q&layer=c&cbll=${mapPopup.latitude},${mapPopup.longitude}&cbp`} target="_blank" rel="noreferer" className="text-blue-600 font-semibold  flex items-center">
                                    <span>Google Street View</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </Popup>
                    : null
            }

            <ScaleControl position="bottom-right" style={scaleControlStyleNoPadding} />
            {/* <FullscreenControl position="bottom-right" style={fullscreenControlStyleNoPadding} /> */}
            <NavigationControl position="bottom-right" style={navStyleNoPadding} />
            <GeolocateControl position="bottom-right" style={geolocateStyleNoPadding} />

        </ReactMapGL>


    )
}
