import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment } from "react"
import About from "./About";

export default function AboutDialog(props) {
    const { isPresented, onClose } = props

    return (

        <Transition
            show={isPresented}
            as={Fragment}
        >

            <Dialog
                onClose={() => { onClose && onClose() }}
                className=""
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 z-[200]" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500 transition transform"
                    enterFrom="opacity-0 -translate-y-80"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in-out duration-500 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="-translate-y-80"
                >

                    <Dialog.Panel className="absolute top-0 left-0 flex w-full h-full items-center justify-center p-5 overflow-hidden">

                        <div className="bg-white rounded-lg p-5 z-[200] drop-shadow-lg flex flex-col max-w-xs max-h-80 overflow-y-auto  overflow-hidden">

                            <About />
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>

    )
}