import React from "react";
import { Transition } from "@headlessui/react"
import { ArrowRightOnRectangleIcon, ShareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Fragment } from "react"
import { useAppContext } from "../../contexts/AppContext";
import * as api from "../../api"
import About from "./About";
import GettingStarted from "./GettingStarted";
import { format, render, cancel, register } from 'timeago.js';

export default function SavedMaps(props) {

    const { onClose, setShareDialogInfo, setIsShareDialogPresented } = props
    const appContext = useAppContext()
    const { account, language } = appContext
    const [data, setData] = React.useState([])

    const loadSavedPlaces = () => {
        api.getRequest(appContext.account.access_token)
            .then((response) => {
                setData(response.data)
            })
    }

    const deletePlace = (code) => {
        api.deleteRequestByCode(code, appContext.account.access_token)
            .then((response) => {
                loadSavedPlaces();
            })
    }

    const shareMap = async (code) => {
        const url = window.location.origin + "/" + code
        const shareDetails = { url };


        if (navigator.share) {
            try {
              await navigator
                .share(shareDetails)
                .then(() =>
                  console.log("Hooray! Your content was shared to tha world")
                );
            } catch (error) {
              console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
          } else {
            setShareDialogInfo({
                url:url,
                title:"Share"
            })
            setIsShareDialogPresented(true)
          }

        
    }

    React.useEffect(() => {
        if (account === null) {
            return
        }
        loadSavedPlaces()
    }, [account])


    function getMiddle(prop, markers) {
        let values = markers.map(m => m[prop]);
        let min = Math.min(...values);
        let max = Math.max(...values);
        if (prop === 'lng' && (max - min > 180)) {
            values = values.map(val => val < max - 180 ? val + 360 : val);
            min = Math.min(...values);
            max = Math.max(...values);
        }
        let result = (min + max) / 2;
        if (prop === 'lng' && result > 180) {
            result -= 360
        }
        return result;
    }

    function findCenter(markers) {
        return {
            lat: getMiddle('lat', markers),
            lng: getMiddle('lng', markers)
        }
    }


    const mapboxStaticImage = (data) => {
        const { places, stations } = data

        const latlngs = places.concat(stations).map((p) => {
            return {
                lng: p.latlng.longitude,
                lat: p.latlng.latitude,
            }
        })

        var placesPins = places.map((p) => {
            return `pin-s+${p.color}(${p.latlng.longitude},${p.latlng.latitude})`
        })

        var stationPins = stations.map((p) => {
            return `pin-s+${p.line_color_hex}(${p.latlng.longitude},${p.latlng.latitude})`
        })

        const center = findCenter(latlngs)
        const pins = placesPins.concat(stationPins).join(",")
        const key = "pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdlMW83MngxMjJvcGFhN2d2cmNkNyJ9.K2ko2jB7iJ8o-_6C6WeNyw"
        const size = "400x200"
        const latlng = center.lng + "," + center.lat + ",12,0"
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${pins}/${latlng}/${size}@2x?access_token=${key}`
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex items-center p-3 border-b">
                <p className="font-medium">Saved Maps ({data.length})</p>
            </div>
            {
                data.length === 0 ?
                    <GettingStarted />
                    : null
            }
            <div className="flex flex-col w-full divide-y">
                {
                    data.map((d, i) => (
                        <div className="flex flex-col items-start p-3 gap-3" key={`${d.code}-location`}>

                            <div className="flex items-center justify-center w-full">
                                <a href={window.location.origin + "/" + d.code} target="_blank">
                                    <div className="w-full relative">
                                        <img className="object-cover w-full aspect-auto " src={mapboxStaticImage(d.data)} />
                                        <div className="absolute bottom-0 left-0 w-full from-black/80 bg-gradient-to-t h-full p-2 flex items-end overflow-hidden ">

                                            <div className="text-white  text-ellipsis  overflow-y-auto">
                                                <p className="text-xs mb-1">{format(d.created_at)}</p>

                                                {
                                                (d.data.stations.length + d.data.places.length) > 3 ? 
                                                <p className=" text-xs text-white">{d.data.stations.length + d.data.places.length} locations</p>
                                                :
                                                <div className="flex items-center gap-x-2 gap-y-0 flex-wrap text-xs text-white">
                                                    {d.data && d.data.stations ?
                                                        d.data.stations.map((s, i) => (
                                                            <p key={`station-${i}`}>{s.line_name} · {s.distance.max} mins</p>
                                                        )) : null}

                                                    {d.data && d.data.places ?
                                                        d.data.places.map((s, i) => (
                                                            <p key={`place-${i}`}>{s.name} · {s.distance.meters} meters</p>
                                                        )) : null}
                                                </div>
                                            }
                                                
                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="w-full flex items-center text-sm gap-6 font-medium">
                                <button onClick={(e) => { shareMap(d.code) }} className=" text-primary  flex items-center justify-center">
                                    <ShareIcon className="w-4 h-4" /> <span className="ml-1.5">Share</span>
                                </button>
                                <button onClick={(e) => { deletePlace(d.code) }} className="text-red-400   flex items-center justify-center">
                                    <TrashIcon className="w-4 h-4" /> <span className="ml-1.5">Delete</span>
                                </button>
                            </div>
                        </div>

                    ))
                }
            </div>

            <div className="mt-auto text-sm">

                <div className="p-3 border-b border-t">
                    <About />
                </div>

                <div className="p-3 flex items-center gap-2 font-medium">
                    {
                        account.user.image_url ?
                            <img className="w-6 h-6 rounded-full bg-gray-200 border border-gray-200" src={account.user.image_url} />
                            :
                            <div className="w-6 h-6 rounded-full bg-gray-200 border border-gray-200 flex items-center justify-center">🥷</div>
                    }

                    <p>{account.user.first_name}&nbsp;{account.user.last_name}</p>
                    <button onClick={() => { appContext.logout() }} className="ml-auto text-gray-500 flex items-center">
                        <ArrowRightOnRectangleIcon className="w-4 h-4 mr-1" />
                        <span>Log out</span>
                    </button>
                </div>
            </div>
        </div>
    )

}