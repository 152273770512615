import React, { useState, useCallback, useEffect } from "react"
import { debounce } from "lodash";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/solid";
import * as api from '../../api'
import TrainIcon from "../../Icons/TrainIcon";
export default function PlaceSearchInput(props) {

    const emptyResult = {
        "data": {
            "places": {
                "candidates": []
            },
            "stations": []
        }
    }


    const { onSelectedResult, clearAfterSelected, mapViewState, onSuumoLinkDetected } = props


    const [searchPlacesResponse, setSearchPlacesResponse] = React.useState(emptyResult)
    const [searchText, setSearchText] = React.useState("")
    const [doSearch, setDoSearch] = React.useState(true)
    const [selectedResult, setSelectedResult] = React.useState(null)

    const searchPlaceAPI = async (text, viewState) => {
        var center = viewState !== null ? `${viewState.latitude},${viewState.longitude}` : null
        const response = await api.searchPlace(text.trim(), center)
        return response
    }

    //be careful when using useCallback and debounce, every params that are used inside the function must be passed through to the function
    const findPlace = useCallback(
        debounce((text, viewState) => {
            searchPlaceAPI(text, viewState)
                .then((result) => {
                    setSearchPlacesResponse(result)
                })

        }, 750)
        , [])

    useEffect(() => {
        if (doSearch === false) return;
        if (searchText.length < 2) return;

        if (searchText.startsWith("https://suumo.jp/")) {
            onSuumoLinkDetected(searchText)
            reset()
            return
        }

        findPlace(searchText.trim(), mapViewState)
    }, [searchText, doSearch])

    const handleTextChange = (e) => {
        setDoSearch(true)
        let text = e.target.value;
        setSearchText(text)
    }

    const onSelectedPlace = (result) => {
        setDoSearch(false)
        setSelectedResult({
            type: "place",
            result: result,
        })
    }
    const onSelectedStation = (result) => {
        setDoSearch(false)
        setSelectedResult({
            type: "train_station",
            result: result,
        })
    }

    useEffect(() => {
        if (selectedResult === null) return;
        onSelectedResult(selectedResult)
        if (clearAfterSelected) {
            setSearchText("")
            setSelectedResult(null)
            setSearchPlacesResponse(emptyResult)
        }
    }, [selectedResult])


    const reset = () => {
        setDoSearch(false)
        setSearchText("")
        setSearchPlacesResponse(emptyResult)
    }

    return (
        <div className="flex flex-col w-full relative">
            <div className="bg-white border border-gray-200 rounded  w-full flex items-center relative px-3 gap-3">

                {/* <MapPinIcon className="h-4 w-4 text-gray-500" /> */}
                <input value={searchText} onChange={handleTextChange} placeholder="Search place or train station" type="text" className="h-full px-0 w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300 " />
                <button className="disabled:opacity-0 opacity-100" disabled={searchText.trim().length == 0} id="geocoder-reset-button" type="button" onClick={(e) => {
                    reset()
                }}>
                    <XMarkIcon className="w-5 h-5 text-gray-500" />
                </button>
            </div>

            {/* result */}
            <div className={`absolute top-10 z-20 shadow w-full border bg-white rounded-b flex flex-col divide-y ${searchPlacesResponse && (searchPlacesResponse.data.places.candidates.length > 0 || searchPlacesResponse.data.stations.length > 0) ? "" : "hidden"}`}>

                {
                    searchPlacesResponse.data.stations.map((result, i) => (
                        <div id={`findplace-station-${i}`} key={`station-${i}`} className="w-full text-xs">
                            <div onClick={(e) => {
                                onSelectedStation(result)
                            }} className="w-full px-3 py-2  hover:bg-blue-50 cursor-pointer flex items-center truncate overflow-ellipsis gap-3">
                                <TrainIcon className="flex-none w-4 h-4 text-gray-500" />
                                <div>
                                    <p className="text-sm font-medium mb-0.5">{result.station_name_en.charAt(0).toUpperCase() + result.station_name_en.slice(1)}&nbsp;({result.station_name_jp})</p>
                                    <p className="text-gray-500">{result.prefecture}, {result.neighborhood}, {result.address}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {
                    searchPlacesResponse.data.places.candidates.map((result, i) => (
                        <div id={`findplace-result-${i}`} key={result.place_id} className="w-full text-xs">
                            <div onClick={(e) => {
                                onSelectedPlace(result)
                            }} className="w-full px-3 py-2  hover:bg-blue-50 cursor-pointer flex items-center truncate overflow-ellipsis gap-3">
                                <MapPinIcon className="flex-none w-4 h-4 text-gray-500" />
                                <div>
                                    <p className="text-sm font-medium mb-0.5">{`${result.name}`}</p>
                                    {
                                        result.formatted_address ?
                                            <p className="tex-xs text-gray-500">{`${result.formatted_address}`}</p>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
            {/* end result */}

        </div>
    )
}